<caption class="caption">
  <span class="month-name">{ monthName }</span>
  <span class="year">{ year }</span>
</caption>
<tr class="header">
  {#each dayNames as day}
    <th class="day-name">{ day }</th>
  {/each}
</tr>

<script>
  import { monthName, dayNames } from './index.js'
  import { getNextMonth } from 'cntdys'

  export default {
    computed: {
      monthName: ({ year, month, locale, nextMonth }) => {
        if (nextMonth) {
          const nextMonth = getNextMonth(year, month)
          return monthName(nextMonth.year, nextMonth.month, locale)
        } else {
          return monthName(year, month, locale)
        }
      },
      dayNames: ({ startOfTheWeek, locale }) => dayNames(startOfTheWeek, locale)
    }
  }
</script>

<style>
  .caption {
    font-size: var(--caption-font-size);
  }
</style>
