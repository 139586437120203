<div class="wrapper">
  <div class="flex between">
    <button class="pointer button" on:click="back(year, month)">
      <svg viewBox="0 0 1000 1000">
        <path d="M336.2 274.5l-210.1 210h805.4c13 0 23 10 23 23s-10 23-23 23H126.1l210.1 210.1c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7l-249.1-249c-11-11-11-21 0-32l249.1-249.1c21-21.1 53 10.9 32 32z"></path>
      </svg>
    </button>
    <button class="pointer button" on:click="forward(year, month)">
      <svg viewBox="0 0 1000 1000">
        <path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path>
      </svg>
    </button>
  </div>

  <div class="flex slot">
    <slot></slot>
  </div>
</div>

<script>
  import { getPreviousMonth, getNextMonth } from 'cntdys'

  // Issue in Chrome 62 when switching between months sometimes cell is missing a part of border.
  // Test if needed for newer versions too
  const chromeBordersFix = (tables) => {
    for (let table of tables) {
      table.style.borderSpacing = table.style.borderSpacing === '0px' ? '' : '0px'
    }
  }

  export default {
    methods: {
      back(year, month) {
        this.set(getPreviousMonth(year, month))
        chromeBordersFix(this.root.options.target.getElementsByClassName('weeks'))
      },
      forward(year, month) {
        this.set(getNextMonth(year, month))
        chromeBordersFix(this.root.options.target.getElementsByClassName('weeks'))
      }
    }
  }
</script>

<style>
  :global(.wrapper) {
    /* exposed CSS vars */
    --font-family: Circular,"Helvetica Neue",Helvetica,Arial,sans-serif;

    --border-color: #e4e7e7;
    --border-width: 1px;
    --day-color: #575757;

    --cell-size: 37px;
    --caption-font-size: 1em;
    --day-font-size: .9em;
    --day-name-font-size: .7em;
    --day-name-color: #9e9c9c;

    --hover-color: #e4e7e7;

    --selected-background-color: #00a699;
    --selected-color: #ffffff;

    --today-color: var(--day-name-color);

    --other-month-visibility: hidden;
    --other-day-color: #cacccd;

    --weekend-day-color: var(--day-color);
    --disabled-day-color: var(--other-day-color);

    --range-border-color: rgba(255,255,255,.2);
    --range-background-color: var(--selected-background-color);
    --range-color: var(--selected-color);

    /* Mobile */
    --column-header-display: none;
    /* /exposed CSS vars */

    font-family: var(--font-family);
    display: inline-block;
  }
  :global(.pointer) {
    cursor: pointer;
  }
  :global(.button) {
    outline: none;
    color: transparent;
    background-color: transparent;
    border: var(--border-width) solid var(--border-color);
    border-radius: unset;
    padding: 0 0.5em;
    height: var(--cell-size);
    width: var(--cell-size);
  }
  :global(.button:active) {
    border-style: inset;
  }
  :global(svg) {
    fill: var(--day-color);
  }
  :global(.flex) {
    display: flex;
  }
  :global(.flex.between) {
    justify-content: space-between;
  }
  :global(.header) {
    height: 1.5em;
  }
  :global(.weeks) {
    border-collapse: collapse;
    width: calc((var(--cell-size) + 3px) * 7)
  }
  :global(.day) {
    font-size: var(--day-font-size);
    color: var(--day-color);
    width: var(--cell-size);
    height: var(--cell-size);
    text-align: center;
    border: var(--border-width) solid var(--border-color);
    -webkit-user-select: none;
    user-select: none;
  }
  :global(.day-name) {
    font-weight: normal;
    font-size: var(--day-name-font-size);
    color: var(--day-name-color);
  }
  :global(.day.weekend) {
    color: var(--weekend-day-color);
  }
  :global(.day.today) {
    color: var(--today-color);
  }
  :global(.day:hover) {
    cursor: pointer;
    background-color: var(--hover-color);
  }
  :global(.day.selected) {
    background-color: var(--selected-background-color);
    color: var(--selected-color);
  }
  :global(.day.other-month) {
    color: var(--other-day-color);
    visibility: var(--other-month-visibility);
    border: none;
    pointer-events: none;
  }
  :global(.week.other-month) {
    display: none;
  }
  :global(.day.disabled) {
    color: var(--disabled-day-color);
    pointer-events: none;
  }
</style>
